var exports = {};

const normalizeArgs = (file, args = []) => {
  if (!Array.isArray(args)) {
    return [file];
  }

  return [file, ...args];
};

const NO_ESCAPE_REGEXP = /^[\w.-]+$/;
const DOUBLE_QUOTES_REGEXP = /"/g;

const escapeArg = arg => {
  if (typeof arg !== "string" || NO_ESCAPE_REGEXP.test(arg)) {
    return arg;
  }

  return `"${arg.replace(DOUBLE_QUOTES_REGEXP, "\\\"")}"`;
};

const joinCommand = (file, args) => {
  return normalizeArgs(file, args).join(" ");
};

const getEscapedCommand = (file, args) => {
  return normalizeArgs(file, args).map(arg => escapeArg(arg)).join(" ");
};

const SPACES_REGEXP = / +/g; // Handle `execa.command()`

const parseCommand = command => {
  const tokens = [];

  for (const token of command.trim().split(SPACES_REGEXP)) {
    // Allow spaces to be escaped by a backslash if not meant as a delimiter
    const previousToken = tokens[tokens.length - 1];

    if (previousToken && previousToken.endsWith("\\")) {
      // Merge previous token with current one
      tokens[tokens.length - 1] = `${previousToken.slice(0, -1)} ${token}`;
    } else {
      tokens.push(token);
    }
  }

  return tokens;
};

exports = {
  joinCommand,
  getEscapedCommand,
  parseCommand
};
export default exports;