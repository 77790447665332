import * as _humanSignals2 from "human-signals";

var _humanSignals = "default" in _humanSignals2 ? _humanSignals2.default : _humanSignals2;

var exports = {};
const {
  signalsByName
} = _humanSignals;

const getErrorPrefix = ({
  timedOut,
  timeout,
  errorCode,
  signal,
  signalDescription,
  exitCode,
  isCanceled
}) => {
  if (timedOut) {
    return `timed out after ${timeout} milliseconds`;
  }

  if (isCanceled) {
    return "was canceled";
  }

  if (errorCode !== undefined) {
    return `failed with ${errorCode}`;
  }

  if (signal !== undefined) {
    return `was killed with ${signal} (${signalDescription})`;
  }

  if (exitCode !== undefined) {
    return `failed with exit code ${exitCode}`;
  }

  return "failed";
};

const makeError = ({
  stdout,
  stderr,
  all,
  error,
  signal,
  exitCode,
  command,
  escapedCommand,
  timedOut,
  isCanceled,
  killed,
  parsed: {
    options: {
      timeout
    }
  }
}) => {
  // `signal` and `exitCode` emitted on `spawned.on('exit')` event can be `null`.
  // We normalize them to `undefined`
  exitCode = exitCode === null ? undefined : exitCode;
  signal = signal === null ? undefined : signal;
  const signalDescription = signal === undefined ? undefined : signalsByName[signal].description;
  const errorCode = error && error.code;
  const prefix = getErrorPrefix({
    timedOut,
    timeout,
    errorCode,
    signal,
    signalDescription,
    exitCode,
    isCanceled
  });
  const execaMessage = `Command ${prefix}: ${command}`;
  const isError = Object.prototype.toString.call(error) === "[object Error]";
  const shortMessage = isError ? `${execaMessage}\n${error.message}` : execaMessage;
  const message = [shortMessage, stderr, stdout].filter(Boolean).join("\n");

  if (isError) {
    error.originalMessage = error.message;
    error.message = message;
  } else {
    error = new Error(message);
  }

  error.shortMessage = shortMessage;
  error.command = command;
  error.escapedCommand = escapedCommand;
  error.exitCode = exitCode;
  error.signal = signal;
  error.signalDescription = signalDescription;
  error.stdout = stdout;
  error.stderr = stderr;

  if (all !== undefined) {
    error.all = all;
  }

  if ("bufferedData" in error) {
    delete error.bufferedData;
  }

  error.failed = true;
  error.timedOut = Boolean(timedOut);
  error.isCanceled = isCanceled;
  error.killed = killed && !timedOut;
  return error;
};

exports = makeError;
export default exports;