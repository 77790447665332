var exports = {};
const aliases = ["stdin", "stdout", "stderr"];

const hasAlias = options => aliases.some(alias => options[alias] !== undefined);

const normalizeStdio = options => {
  if (!options) {
    return;
  }

  const {
    stdio
  } = options;

  if (stdio === undefined) {
    return aliases.map(alias => options[alias]);
  }

  if (hasAlias(options)) {
    throw new Error(`It's not possible to provide \`stdio\` in combination with one of ${aliases.map(alias => `\`${alias}\``).join(", ")}`);
  }

  if (typeof stdio === "string") {
    return stdio;
  }

  if (!Array.isArray(stdio)) {
    throw new TypeError(`Expected \`stdio\` to be of type \`string\` or \`Array\`, got \`${typeof stdio}\``);
  }

  const length = Math.max(stdio.length, aliases.length);
  return Array.from({
    length
  }, (value, index) => stdio[index]);
};

exports = normalizeStdio; // `ipc` is pushed unless it is already present

exports.node = options => {
  const stdio = normalizeStdio(options);

  if (stdio === "ipc") {
    return "ipc";
  }

  if (stdio === undefined || typeof stdio === "string") {
    return [stdio, stdio, stdio, "ipc"];
  }

  if (stdio.includes("ipc")) {
    return stdio;
  }

  return [...stdio, "ipc"];
};

export default exports;